<script>
  import { useStore } from 'vuex'
  import i18n from "../i18n";
  import { mapGetters } from 'vuex'
  import { mapActions} from "vuex";
  import { isProxy, toRaw } from 'vue';
  import serverConfig   from '@/helpers/config';
  import Swal from "sweetalert2";
  import axios from 'axios';
  import moment from 'moment';

//import Timeline from '../views/pages/timeline.vue';
  /**
   * Nav-bar Component
   */
  export default {
    setup() {  
      const store = useStore();
      let tk = 'Bearer ' + localStorage.getItem('tk')  
      return { store, tk };
    },
    data() {
      return {
        config:serverConfig,
        epService:serverConfig,
        languages: [
          {
            flag: require("@/assets/images/flags/italy.svg"),
            language: "it",
            title: "italiana",
          },
        ],
        lan: i18n.locale,
        text: null,
        flag: null,
        value: null,
        myVar: 1,
        username:'',
        userData:{},
        dataAlert:{},
        dataCart:{},
        page: 1,
        perPage: 10,
        sortRes:'asc',
        
        pages: [],
        paginated:{},
        select:'all',
        search:'',
        responseList:[],
        n_results:0,
        unreadMsg:0,
        unreadMsgList:[],
        interval:null,
        
      };
    },
    components: {
       // Timeline
     //SimpleBar
    },
    beforeMount() {
      
      if (this.$store.state.auth.currentUser){
        this.userData = this.$store.state.auth.currentUser
        let usr 

        if (this.userData.username!=''){
          if ( this.userData.username.includes('@')){
            usr = this.userData.username.split('@')
            this.username = usr[0]+'@'
          } else {
            this.username = this.userData.username
          }
        }
        
      }
      this.getAlerts()
    },
    methods: {
      ...mapActions('ecommercesettings', ['getCartByOrd','deleteItem']),
      axiosInterceptor(){
      axios.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
            if (error.response && error.response.status === 401) {
              Swal.fire({
                title:"Accesso non autorizzato",
                text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                this.$router.push('/login')
                if(localStorage.getItem('tk')){
                  localStorage.removeItem('tk')
                }
                return Promise.reject('Unauthorized access');
            } else if (error.response && error.response.status  === 404) {
                Swal.fire({
                  title:"Nessun risultato trovato",
                  text:"Mi spiace, nessun risultato trovato",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('The server cannot find the requested resource');
            }  else if (error.response && error.response.status  === 412) {
              Swal.fire({
                title:"Credenziali mancanti",
                text:"Inserisci delle credenziali valide, per favore.",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              return Promise.reject('The server cannot find the requested resource');
          } else if (error.response && error.response.status  === 423) {
                Swal.fire({
                  title:"Risorsa non accessibile",
                  text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                this.$router.push('/login')
                if(localStorage.getItem('tk')){
                  localStorage.removeItem('tk')
                }                
                return Promise.reject('The server cannot find the requested resource');
            }
            else if (error.response && error.response.status  === 500) {
              Swal.fire({
                title:"Errore Server",
                text:"Mi spiace, ma riscontriamo degli errori sul server.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Internal Server Error');
            }
            else if (error.response && error.response.status  === 502) {
                Swal.fire({
                  title:"Errore Server",
                  text:"Mi spiace, ma riscontriamo degli errori sul server.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('Bad Gateway');
            }
            else if (error.response && error.response.status  === 503) {
              Swal.fire({
                title:"Servizio non disponibile",
                text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Service Unavailable');
            } else {
              console.log(error.response.status )
              Swal.fire({
                title:"Errore imprevisto",
                text:"Ops! si è verificato un problema...riprova, per favore.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              
            }

        }
      );
      
      },
      convertDateL3(date) {
        moment.locale('it')
         if (date) {
          return moment.unix(date).format('LLL')
         } 
      },
      test(path){
        this.$route.push(path)
      },
      getAlerts(){
        if ( localStorage.getItem('tk') ) {
          this.axiosInterceptor()
          axios.get(`${serverConfig.EP}notifications/byuser/msgunread`, 
            { 
              headers: { 'authorization':'Bearer '+ localStorage.getItem('tk')}
            }
          ).then( response => {
              this.unreadMsgList = response.data;
              this.unreadMsg = response.data.length
          })
        }      
      },
    getSearchList(){
      if ( localStorage.getItem('tk') ) {
        this.axiosInterceptor()
        axios.get(`${serverConfig.EP}products/search-per-page/`, 
          { 
            params: { 
              perPage: this.perPage,
              page: this.page,
              sortRes: this.sortRes,
              select: this.select,
              search: this.search,
            }, 
            headers: { 'authorization':'Bearer '+ localStorage.getItem('tk')}
          }
        ).then( response => {
            this.responseList = response.data.results.data;
            //this.paginated = response.data.results
            this.n_results = response.data.n_results
        })
      }
    },
      checkCart(){
        if (Object.keys(this.$store.state.ecommercesettings).length>0){    
          if (this.$store.state.ecommercesettings.orx){
            localStorage.setItem('orx',this.$store.state.ecommercesettings.orx)
          }
        }
      },
      formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.',',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      },
      isCustomDropdown() {
        //Search bar
      
        var searchOptions = document.getElementById("search-close-options");
        var dropdown = document.getElementById("search-dropdown");
        var searchInput = document.getElementById("search-options");
  
        searchInput.addEventListener("focus", () => {
          var inputLength = searchInput.value.length;
          if (inputLength > 0) {
            dropdown.classList.add("show");
            searchOptions.classList.remove("d-none");
          } else {
            dropdown.classList.remove("show");
            searchOptions.classList.add("d-none");
          }
        });
  
        searchInput.addEventListener("keyup", () => {
          var inputLength = searchInput.value.length;
          if (inputLength > 0) {
            dropdown.classList.add("show");
            searchOptions.classList.remove("d-none");
          } else {
            dropdown.classList.remove("show");
            searchOptions.classList.add("d-none");
          }
        });
  
        searchOptions.addEventListener("click", () => {
          searchInput.value = "";
          dropdown.classList.remove("show");
          searchOptions.classList.add("d-none");
        });
  
        document.body.addEventListener("click", (e) => {
          if (e.target.getAttribute("id") !== "search-options") {
            dropdown.classList.remove("show");
            searchOptions.classList.add("d-none");
          }
        });
        
      },
      toggleHamburgerMenu() {
        var windowSize = document.documentElement.clientWidth;
  
        if (windowSize > 767)
          document.querySelector(".hamburger-icon").classList.toggle("open");
  
        //For collapse horizontal menu
        if (
          document.documentElement.getAttribute("data-layout") === "horizontal"
        ) {
          document.body.classList.contains("menu") ?
            document.body.classList.remove("menu") :
            document.body.classList.add("menu");
        }
  
        //For collapse vertical menu
        if (document.documentElement.getAttribute("data-layout") === "vertical") {
          if (windowSize < 1025 && windowSize > 767) {
            document.body.classList.remove("vertical-sidebar-enable");
            document.documentElement.getAttribute("data-sidebar-size") == "sm" ?
              document.documentElement.setAttribute("data-sidebar-size", "") :
              document.documentElement.setAttribute("data-sidebar-size", "sm");
          } else if (windowSize > 1025) {
            document.body.classList.remove("vertical-sidebar-enable");
            document.documentElement.getAttribute("data-sidebar-size") == "lg" ?
              document.documentElement.setAttribute("data-sidebar-size", "sm") :
              document.documentElement.setAttribute("data-sidebar-size", "lg");
          } else if (windowSize <= 767) {
            document.body.classList.add("vertical-sidebar-enable");
            document.documentElement.setAttribute("data-sidebar-size", "lg");
          }
        }
  
        //Two column menu
        if (document.documentElement.getAttribute("data-layout") == "twocolumn") {
          document.body.classList.contains("twocolumn-panel") ?
            document.body.classList.remove("twocolumn-panel") :
            document.body.classList.add("twocolumn-panel");
        }
      },
      toggleMenu() {
        this.$parent.toggleMenu();
      },
      toggleRightSidebar() {
        this.$parent.toggleRightSidebar();
      },
      initFullScreen() {
        document.body.classList.toggle("fullscreen-enable");
        if (
          !document.fullscreenElement &&
          /* alternative standard method */
          !document.mozFullScreenElement &&
          !document.webkitFullscreenElement
        ) {
          // current working methods
          if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
          } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen();
          } else if (document.documentElement.webkitRequestFullscreen) {
            document.documentElement.webkitRequestFullscreen(
              Element.ALLOW_KEYBOARD_INPUT
            );
          }
        } else {
          if (document.cancelFullScreen) {
            document.cancelFullScreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
          }
        }
      },
      setLanguage(locale, country, flag) {
        this.lan = locale;
        this.text = country;
        this.flag = flag;
        document.getElementById("header-lang-img").setAttribute("src", flag);
        i18n.global.locale = locale;
      },
      toggleDarkMode() {
        if (document.documentElement.getAttribute("data-layout-mode") == "dark") {
          document.documentElement.setAttribute("data-layout-mode", "light");
        } else {
          document.documentElement.setAttribute("data-layout-mode", "dark");
        }
      },
      initTopbarComponents() {
        function updateCartPrice() {
          var currencySign = "$";
          var subtotal = 0;
          Array.from(document.getElementsByClassName("cart-item-price")).forEach(function (e) {
            subtotal += parseFloat(e.innerHTML);
          });
          if (document.getElementById("cart-item-total")) {
            document.getElementById("cart-item-total").innerHTML = currencySign + subtotal.toFixed(2);
          }
        }
        if (document.getElementsByClassName("dropdown-item-cart")) {
          var dropdownItemCart = document.querySelectorAll(".dropdown-item-cart").length;
          Array.from(document.querySelectorAll("#page-topbar .dropdown-menu-cart .remove-item-btn")).forEach(function (item) {
            item.addEventListener("click", function () {
              dropdownItemCart--;
              this.closest(".dropdown-item-cart").remove();
              Array.from(document.getElementsByClassName("cartitem-badge")).forEach(function (e) {
                e.innerHTML = dropdownItemCart;
              });
              updateCartPrice();
              if (document.getElementById("empty-cart")) {
                document.getElementById("empty-cart").style.display = dropdownItemCart == 0 ? "block" : "none";
              }
              if (document.getElementById("checkout-elem")) {
                document.getElementById("checkout-elem").style.display = dropdownItemCart == 0 ? "none" : "block";
              }
            });
          });
          Array.from(document.getElementsByClassName("cartitem-badge")).forEach(function (e) {
            e.innerHTML = dropdownItemCart;
          });
          if (document.getElementById("empty-cart")) {
            document.getElementById("empty-cart").style.display = "none";
          }
          if (document.getElementById("checkout-elem")) {
            document.getElementById("checkout-elem").style.display = "block";
          }
          updateCartPrice();
        }
  
        // notification messages
        if (document.getElementsByClassName("notification-check")) {
          Array.from(document.querySelectorAll(".notification-check input")).forEach(function (element) {
            element.addEventListener("click", function (el) {
              el.target.closest(".notification-item").classList.toggle("active");
            });
          });
        }
      },
      getDetailCart(){
        if ( localStorage.getItem("orx") ) {
          this.getCartByOrd(localStorage.getItem("orx"))
        }
      },
      removeItem(id){
        if ( localStorage.getItem("orx") ) {
          Swal.fire(
            {
              title: this.$t("t-delete-confirm-setting-title"),
              text: this.$t("t-delete-confirm-setting-text"),
              icon: this.$t("t-delete-confirm-setting-icon"),
              showCancelButton: true,
              cancelButtonText: this.$t("t-delete-confirm-cancel-button-text"),
              cancelButtonColor: this.$t("t-delete-confirm-setting-cancelButtonColor"),
              confirmButtonColor: this.$t("t-delete-confirm-setting-confirmButtonColor"),
              confirmButtonText: this.$t("t-delete-confirm-setting-confirmButtonText")
            }
          ).then(() => {

            this.deleteItem(id, localStorage.getItem("orx"))
          })

        }
      },
      getActiveCart() {
        if ( localStorage.getItem("orx") ) {
            axios.get(`${serverConfig.EP}carts/orx/${localStorage.getItem("orx")}`, 
            { 
              headers: { 'authorization': 'Bearer '+ localStorage.getItem('tk')}
            }
          ).then((response)=>{
                if (response.data){
                  this.dataCart = response.data
                }
          })
        }
      },
      getDataProxyCart(data){
        let rawData = data; 
        if (isProxy(data))
        { 
          rawData = toRaw(data)
          return rawData
        }
      },
      goTo(url){
        this.$router.push(url)
      },
      readMSG(item,type) {
        let url = false
        if (type=='tasks') {
          url = '/tasks/detail/'+item.entityID
        }
        if (type=='events') {
          url = '/my-calendar'
        }
        return url
      },
    },
    computed:{ 
      ...mapGetters('ecommercesettings', ['getCart', ])
    },
    mounted() {
      this.checkCart()
      this.getDetailCart()
      document.addEventListener("scroll", function () {
        var pageTopbar = document.getElementById("page-topbar");
        if (pageTopbar) {
          document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50 ? pageTopbar.classList.add(
            "topbar-shadow") : pageTopbar.classList.remove("topbar-shadow");
        }
      });
      if (document.getElementById("topnav-hamburger-icon"))
        document
          .getElementById("topnav-hamburger-icon")
          .addEventListener("click", this.toggleHamburgerMenu);
  
     // this.isCustomDropdown();
      this.initTopbarComponents();
     
    },
    created(){
    this.interval = setInterval(() =>{
      this.getAlerts()}, 60000)
    },
   
  };
  </script>
  
  <template>
    <header id="page-topbar">
      <div class="layout-width">
        <div class="navbar-header">
          <div class="d-flex">
            <div class="navbar-brand-box horizontal-logo">
              <router-link to="/" class="logo logo-dark">
                <span class="logo-sm">
                  <img :src="config.EP+'/public/assets/img/logo-dark.png'" alt="" height="30" />
                </span>
                <span class="logo-lg">
                  <img :src="config.EP+'/public/assets/img/logo-dark.png'"  alt="" height="30" />
                </span>
              </router-link>
  
              <router-link to="/" class="logo logo-light">
                <span class="logo-sm">
                  <img :src="config.EP+'/public/assets/img/logo-light.png'"  alt="" height="30" />
                </span>
                <span class="logo-lg">
                  <img :src="config.EP+'/public/assets/img/logo-light.png'" alt="" height="30" />
                </span>
              </router-link>
            </div>
  
            <button type="button" class="
                btn btn-sm
                px-3
                fs-16
                header-item
                vertical-menu-btn
                topnav-hamburger shadow-none
              " id="topnav-hamburger-icon">
              <span class="hamburger-icon">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </button>
  

          </div>
  
          <div class="d-flex align-items-center">
            <div class="dropdown d-md-none topbar-head-dropdown header-item">
              <span class="logo-lg">
                <img :src="config.EP+'/public/assets/img/logo-dark.png'"  alt="" height="30" />
              </span>
            </div>
            <div class="ms-1 header-item d-none d-sm-flex">
              <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none"
                data-toggle="fullscreen" @click="initFullScreen">
                <i class="bx bx-fullscreen fs-22"></i>
              </button>
            </div>

  
          <div class="dropdown topbar-head-dropdown ms-1 header-item">
              <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none"
                id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                <i class="bx bx-bell fs-22"></i>
                
                <span class="position-absolute topbar-badge fs-12 translate-middle badge rounded-pill bg-danger" v-if="unreadMsg>0">
                  {{ unreadMsg }} <span class="visually-hidden">unread messages</span></span>

              </button>
              <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-notifications-dropdown"  @click.capture.stop>
                <div class="dropdown-head bg-black-1 bg-pattern rounded-top">
                  <div class="p-3">
                    <div class="row align-items-center">
                      <div class="col">
                        <h6 class="m-0 fs-16 fw-semibold text-white">
                          Notifiche
                        </h6>
                      </div>
                      <div class="col-auto dropdown-tabs">
                        <span class="badge badge-soft-light fs-13"> {{unreadMsg}} Non lette</span>
                      </div>
                    </div>
                  </div>
  
                  <div class="px-2 pt-2">
                    <ul class="nav nav-tabs dropdown-tabs nav-tabs-custom" data-dropdown-tabs="true"
                      id="notificationItemsTab" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="tab" href="#all-noti-tab" role="tab"
                          aria-selected="false" @click.capture.stop>
                          Tutte ({{unreadMsg}})
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#messages-tab" role="tab" aria-selected="true"
                          @click.capture.stop>
                          Messaggi
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
  
                <div class="tab-content" id="notificationItemsTabContent">
  
                  <div class="tab-pane fade py-2 ps-2 show active" id="all-noti-tab" role="tabpanel">
                     
                    <template v-if="unreadMsg>0">
                      <div v-for="(item, index) in unreadMsgList" :key="index" >
                        
                        <div class="text-reset notification-item d-block dropdown-item position-relative"  v-if="index<=3">
                        <div class="d-flex">
                          <div class="avatar-xs me-3">
                            <template v-if="item.entity=='events'">
                              <span class="avatar-title bg-soft-primary text-info rounded-circle fs-16">
                                <i class="bx bx-calendar"></i>
                              </span>
                            </template>
                            <template v-if="item.entity=='tasks'">
                              <span class="avatar-title bg-soft-info text-info rounded-circle fs-16">
                                <i class="mdi mdi-clipboard-edit-outline"></i>
                              </span>
                            </template>
                            <template v-if="item.entity=='projects'">
                              <span class="avatar-title bg-soft-warning text-info rounded-circle fs-16">
                                <i class="mdi mdi-account-hard-hat "></i>
                              </span>
                            </template>
                          </div>
                          <div class="flex-1">
                            <a :href="readMSG(item,item.entity)" class="stretched-link" >
                              <h6 class="mt-0 mb-2 lh-base">
                               <strong>{{ item.title }}</strong>
                              </h6>
                            </a>
                            <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                              <span><i class="mdi mdi-clock-outline"></i> {{convertDateL3(item.start_tmp)}}</span>
                            </p>
                            <template v-if="item.entity=='tasks'">
                              <a :href="readMSG(item,'tasks')" class="text-primary d-inline-block edit-item-btn" >
                                Leggi
                              </a>
                            </template>
                            <template v-if="item.entity=='events'">
                              <a :href="readMSG(item,'events')" class="text-primary d-inline-block edit-item-btn" >
                                Leggi
                              </a>
                            </template>
                          </div>

                        </div>
                        </div> 
                      </div>
                      <div class="my-3 text-center" v-if="unreadMsg>0" >
                        <a href="/my-dashboard/notifications" class="text-secondary" >Apri tutte </a>
                      </div>
                    </template>

                      <div class="tab-pane fade p-4"  v-if="unreadMsg==0">
                        <div class="w-25 w-sm-50 pt-3 mx-auto">
                          <img src="@/assets/images/svg/bell.svg" class="img-fluid" alt="user-pic" />
                        </div>
                        <div class="text-center pb-5 mt-2">
                          <h6 class="fs-18 fw-semibold lh-base">
                            Non ci sono ancora notifiche
                          </h6>
                        </div>
                      </div>
                  </div>
  
                  <div class="tab-pane fade py-2 ps-2" id="messages-tab" role="tabpanel" aria-labelledby="messages-tab">

                    <div class="tab-pane fade p-4"  v-if="unreadMsg==0">
                      <div class="w-25 w-sm-50 pt-3 mx-auto">
                        <img src="@/assets/images/svg/bell.svg" class="img-fluid" alt="user-pic" />
                      </div>
                      <div class="text-center pb-5 mt-2">
                        <h6 class="fs-18 fw-semibold lh-base">
                          Non ci sono ancora notifiche
                        </h6>
                      </div>
                    </div>
                  </div>
  
                </div>
              </div>
            </div>
            <!-- Carrello -->
          <div class="dropdown topbar-head-dropdown ms-1 header-item">
              <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none"
                id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false" @click="getActiveCart">
                <i class="bx bx-cart fs-22"></i>
                <template v-if="getCart">
                  <template v-if="getCart">
                    <span class="position-absolute topbar-badge fs-12 translate-middle badge rounded-pill bg-success">
                      {{ getCart.totalQta }}<span class="visually-hidden">Carrello attivo</span>
                    </span>
                  </template>
                </template>  
              </button>
              <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-notifications-dropdown" @click.capture.stop>
                <div class="dropdown-head bg-cart  rounded-top">
                  <div class="p-3">
                    <div class="row align-items-center">
                      <div class="col">
                        <h6 class="m-0 fs-16 fw-semibold text-white">
                          Carrello quotazione
                        </h6>
                      </div>
                      <div class="col-auto dropdown-tabs">
                        <template v-if="getCart">
                          <span class="badge badge-soft-light fs-13">  {{ getCart.totalQta }} prodotti</span>
                        </template>
                      </div>
                    </div>
                  </div>
  
                  <div class="px-2 pt-2">
                    <ul class="nav nav-tabs dropdown-tabs nav-tabs-custom" data-dropdown-tabs="true"
                      id="notificationItemsTab" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="tab" href="#all-noti-tab" role="tab"
                          aria-selected="false" @click.capture.stop>
                          Elenco prodotti
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
  
                <div class="tab-content" id="notificationItemsTabContent">
                  
                  <div class="tab-pane box-scroll fade  py-2 ps-3 pr-3 show active" id="all-noti-tab" role="tabpanel">
                    <div class="row row-cart-header">
                      <div class="col-6 pt-10"><strong>Nome</strong></div>
                      <div class="col pt-10"><strong>Prezzo</strong></div>
                      <div class="col pt-10"><strong>Qta</strong></div>
                       <div class="col pt-10"></div>
                    </div>       
                   <div class="row row-cart-items mt-20" v-for="item in dataCart.rows" :key="item._id">
                      <div class="col-6"><strong>{{ item.detail_item.Nome}}</strong> <br>{{ item.gcode}}</div>

                      <div class="col pt-10">{{ formatPrice(item.detail_item.prezzo) }}</div>
                      
                      <div class="col pt-10">{{ formatPrice(item.detail_item.qta) }}</div>
                      <div class="col pt-10">
                        <button class="text-danger d-inline-block remove-item-btn" @click="removeItem(item._id)"><i class="ri-delete-bin-5-fill fs-16"></i></button>
                      </div>
                    </div>

                  </div>
                </div>
                <!-- Buttons Grid -->
                  <div class="d-grid gap-2 mt-20" >
                  <b-button variant="primary" @click="goTo('/ecommerce/summary-cart')"><i class="bx bx-cart fs-32"></i> Dettaglio carrello</b-button>
                 
                  </div>

                  <div class="row row-cart-total mt-5 py-2 ps-3 pr-3 pt-5 pb-5" >
                      <div class="col ">Totale</div>
                      <div class="col "></div>
                      <div class="col "></div>
                      <div class="col-7 "><div class="float-right">{{ formatPrice(dataCart.totalPrice)}} <span class="bx bx-euro"></span></div></div>
                    </div>
              </div>
            </div>
            <!-- / Carrello -->
            <div class="dropdown ms-sm-3 header-item topbar-user">
              <button type="button" class="btn shadow-none" id="page-header-user-dropdown" data-bs-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <span class="d-flex align-items-center">
                  <img class="rounded-circle header-profile-user" src="@/assets/images/user-dummy-img.jpg"
                    alt="Header Avatar" />
                  <span class="text-start ms-xl-2">
                    <span class=" d-none d-xl-inline-block ms-1 fw-medium user-name-text">{{username}}</span>
                    
                    
                  </span>
                </span>
              </button>
              <div class="dropdown-menu dropdown-menu-end">
                <!-- item-->
                <h6 class="dropdown-header"></h6>
                <!--<router-link class="dropdown-item" to="/pages/profile"><i
                    class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                  <span class="align-middle">Profile</span>
                </router-link>
                <router-link class="dropdown-item" to="/chat">
                  <i class=" mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>
                  <span class="align-middle">Messages</span>
                </router-link>
                <router-link class="dropdown-item" to="/apps/tasks-kanban">
                  <i class="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i>
                  <span class="align-middle">Taskboard</span>
                </router-link>
                <router-link class="dropdown-item" to="/pages/faqs"><i
                    class="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>
                  <span class="align-middle">Help</span>
                </router-link>
                <div class="dropdown-divider"></div>
                <router-link class="dropdown-item" to="/pages/profile"><i
                    class="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i>
                  <span class="align-middle">Balance : <b>$5971.67</b></span>
                </router-link>
                <router-link class="dropdown-item" to="/pages/profile-setting"><span
                    class="badge bg-soft-success text-success mt-1 float-end">New</span><i
                    class="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>
                  <span class="align-middle">Settings</span>
                </router-link>
                <router-link class="dropdown-item" to="/auth/lockscreen-basic"><i
                    class="mdi mdi-lock text-muted fs-16 align-middle me-1"></i>
                  <span class="align-middle">Lock screen</span>
                </router-link>-->
                <a class="dropdown-item" href="/logout"><i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                  <span class="align-middle" data-key="t-logout">Esci</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </template>