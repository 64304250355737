<script>
  import NavBar from "@/components/nav-bar";
  import RightBar from "@/components/right-bar";
  import Footer from "@/components/footer";
  
  export default {
    watch: {
      $route: {
        handler: "onRoutechange",
        immediate: true,
        deep: true,
      },
    },
    methods: {
      onRoutechange(ele) {
        this.initActiveMenu(ele.path);
      },
      initActiveMenu(ele) {
        setTimeout(() => {
          if (document.querySelector("#navbar-nav")) {
            let a = document
              .querySelector("#navbar-nav")
              .querySelector('[href="' + ele + '"]');
  
            if (a) {
              a.classList.add("active");
              let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
              if (parentCollapseDiv) {
                parentCollapseDiv.classList.add("show");
                parentCollapseDiv.parentElement.children[0].classList.add(
                  "active"
                );
                parentCollapseDiv.parentElement.children[0].setAttribute(
                  "aria-expanded",
                  "true"
                );
                if (
                  parentCollapseDiv.parentElement.closest(
                    ".collapse.menu-dropdown"
                  )
                ) {
                  parentCollapseDiv.parentElement
                    .closest(".collapse")
                    .classList.add("show");
                  if (
                    parentCollapseDiv.parentElement.closest(".collapse")
                      .previousElementSibling
                  )
                    parentCollapseDiv.parentElement
                      .closest(".collapse")
                      .previousElementSibling.classList.add("active");
                }
              }
            }
          }
        }, 1000);
      },
    },
    components: {
      NavBar,
      RightBar,
      Footer
    },
  };
  </script>
  
  <template>
    <div>
      <div id="layout-wrapper">
        <NavBar />
        <!-- ========== App Menu ========== -->
        <div class="app-menu navbar-menu">
          <!-- LOGO -->
          <div class="navbar-brand-box" style="background-color:#fff">
            <!-- Dark Logo-->
            <router-link to="/" class="logo logo-dark">
              <span class="logo-sm">
                <img src="@/assets/images/logo-sm.png" alt="" height="22" />
              </span>
              <span class="logo-lg">
                <img src="@/assets/images/logo-dark.png" alt="" height="17" />
              </span>
            </router-link>
            <!-- Light Logo-->
            <router-link to="/" class="logo logo-light">
              <span class="logo-sm">
                <img src="@/assets/images/logo-sm.png" alt="" height="22" />
              </span>
              <span class="logo-lg">
                <img src="@/assets/images/logo-light.png" alt="" height="17" />
              </span>
            </router-link>
            <button type="button" class="
                btn btn-sm
                p-0
                fs-20
                header-item
                float-end
                btn-vertical-sm-hover
              " id="vertical-hover">
              <i class="ri-record-circle-line"></i>
            </button>
          </div>
          <div id="scrollbar">
            <div class="container-fluid">
              <ul class="navbar-nav h-100" id="navbar-nav">
                <li class="menu-title">
                  <span data-key="t-menu"> {{ $t("t-menu") }}</span>
                </li>
              <!-- Area Personale -->
                <li class="nav-item" v-if="$store.state.layout.applicationLicense=='wms' || $store.state.layout.applicationLicense == 'all'">
            <a href="#sidebarMyImpiantino" class="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarProjects" data-key="t-projects">
              <span class="logo-sm pr-2">
                <i class="mdi mdi-view-dashboard-outline"></i>
              </span>
              {{ $t("t-personal") }}
            </a>
            <div class="collapse menu-dropdown" id="sidebarMyImpiantino">
              <ul class="nav nav-sm flex-column">
                <li class="nav-item">
                  <router-link to="/my-dashboard" class="nav-link" data-key="t-my-dashboard">
                    {{ $t("t-my-dashboard") }}
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/my-calendar" class="nav-link" data-key="t-my-calendar">
                    {{ $t("t-my-calendar") }}
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/my-projects/list-view" class="nav-link" data-key="t-projects-list">
                    {{ $t("t-list") }} commesse
                  </router-link>
                </li>
                <li class="nav-item" v-if="this.$route.name=='my-projects-detail'">
                  <template v-if="this.$route.params.id">
                    <router-link :to="'/my-projects/detail/'+ this.$route.params.id " class="nav-link" data-key="my-projects-detail">
                      Dettaglio commessa   
                    </router-link>
                  </template>
                </li>
                <li class="nav-item" v-if="this.$route.name=='my-project-dms'">
                  <template v-if="this.$route.params.id">
                    <router-link :to="'/my-projects/detail/dms/'+ this.$route.params.id " class="nav-link" data-key="my-project-dms">
                      Documentazione commessa
                    </router-link>
                  </template>
                </li>
              </ul>
            </div>
                </li>
            <!-- / Personale -->
            <!-- Attività 'TASK'  -->
                  <li class="nav-item" v-if="$store.state.layout.applicationLicense=='wms' || $store.state.layout.applicationLicense == 'all'">
                        <a class="nav-link menu-link" href="#sidebarTask" data-bs-toggle="collapse" role="button"
                          aria-expanded="false" aria-controls="sidebarTaskAdmin">
                          <i class="mdi mdi-clipboard-edit-outline"></i>
                          <span data-key="t-task-admin"> Attività richieste</span>
                        </a>
                        <div class="collapse menu-dropdown" id="sidebarTask">
                          <ul class="nav nav-sm flex-column">
                            <li class="nav-item">
                              <router-link to="/tasks/list-view" class="nav-link" data-key="tasks-list">
                                {{ $t("t-list") }} attività
                              </router-link>
                            </li>
                            <li class="nav-item" v-if="this.$route.name=='tasks-detail'">
                              <template v-if="this.$route.params.id">
                                <router-link :to="'/tasks/detail/'+ this.$route.params.id " class="nav-link" data-key="tasks-detail">
                                  Dettaglio attività   
                                </router-link>
                              </template>
                            </li>
                            <li class="nav-item">
                              <router-link to="/tasks-reports/list-view" class="nav-link" data-key="tasks-reports">
                                {{ $t("t-list") }} rapportini completati
                              </router-link>
                            </li>
                            <li class="nav-item" v-if="this.$route.name=='tasks-reports-detail'">
                              <template v-if="this.$route.params.id">
                                <router-link :to="'/tasks-reports/detail/'+ this.$route.params.id +'/'+this.$route.params.worksiteid +'/'+this.$route.params.taskid " class="nav-link" data-key="intervention-reports-detail">
                                  Dettaglio rapporto d'intervento   
                                </router-link>
                              </template>
                            </li>
                          </ul>
                        </div>
                  </li>   
            <!-- / Attività richieste -->
                <li class="nav-item">
                  <a class="nav-link menu-link" href="#sidebarApps" data-bs-toggle="collapse" role="button"
                    aria-expanded="false" aria-controls="sidebarApps">
                    <span class="logo-sm pr-2">
                <img src="@/assets/images/logo-store-sm.png" alt="" height="22" />
              </span>
                    <span data-key="t-apps"> {{ $t("t-app-store") }} sdads</span>
                  </a>
                  <div class="collapse menu-dropdown" id="sidebarApps">
                    <ul class="nav nav-sm flex-column">
                      <li class="nav-item">
                        <router-link to="/calendar" class="nav-link" data-key="t-calendar">
                          {{ $t("t-calendar") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/chat" class="nav-link" data-key="t-chat">
                          Chat assistenza
                        </router-link>
                      </li>
                 
    
                <!-- NUOVO MODULO  -->
                      <li class="nav-item">
                        <a href="#sidebarEcommerce" class="nav-link" data-bs-toggle="collapse" role="button"
                          aria-expanded="false" aria-controls="sidebarEcommerce" data-key="t-ecommerce">
                          {{ $t("t-ecommerce") }}
                        </a>
                        <div class="collapse menu-dropdown" id="sidebarEcommerce">
                          <ul class="nav nav-sm flex-column">
                              <li class="nav-item">
                                <router-link to="/ecommerce/products" class="nav-link" data-key="t-products">
                                  {{ $t("t-products") }}
                                </router-link>
                              </li>
                              <li class="nav-item">
                                <router-link to="/ecommerce/orders" class="nav-link" data-key="t-orders">
                                  {{ $t("t-orders") }}
                                </router-link>
                              </li>
                              
                              <li class="nav-item" v-if="this.$route.params.orx">
                              
                                <router-link :to="'/ecommerce/order/'+ this.$route.params.orx + '/details'" class="nav-link" data-key="order-orx-details">
                                  Dettaglio ordine
                                </router-link>
                              </li>
                          </ul>
                        </div>
                      </li>

                    </ul>
                  </div>
                </li>
  
                <li class="menu-title">
                  <i class="ri-more-fill"></i>
                  <span data-key="t-pages">{{ $t("t-pages") }}ss</span>
                </li>

                <li class="menu-title">
                  <i class="ri-more-fill"></i>
                  <span data-key="t-components">{{ $t("t-components") }}</span>
                </li>
  
                <li class="nav-item">
                  <a class="nav-link menu-link" href="#sidebarUI" data-bs-toggle="collapse" role="button"
                    aria-expanded="false" aria-controls="sidebarUI">
                    <i class="ri-pencil-ruler-2-line"></i>
                    <span data-key="t-base-ui">{{ $t("t-base-ui") }}</span>
                  </a>
                  <div class="collapse menu-dropdown mega-dropdown-menu" id="sidebarUI">

                  </div>
                </li>
  
                <li class="nav-item">
                  <a class="nav-link active" href="#sidebarMore" data-bs-toggle="collapse" role="button"
                    aria-expanded="false" aria-controls="sidebarMore">
                    <i class="ri-briefcase-2-line"></i> Servizi
                  </a>
                  <div class="collapse menu-dropdown show" id="sidebarMore">

                  </div>
                </li>
              </ul>
            </div>
            <!-- Sidebar -->
          </div>
          <!-- Left Sidebar End -->
          <!-- Vertical Overlay-->
          <div class="vertical-overlay"></div>
        </div>
        <!-- ============================================================== -->
        <!-- Start Page Content here -->
        <!-- ============================================================== -->
  
        <div class="main-content">
          <div class="page-content">
            <!-- Start Content-->
            <div class="container-fluid">
              <slot />
            </div>
          </div>
          <Footer />
        </div>
        <RightBar />
      </div>
    </div>
  </template>